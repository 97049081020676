
  import { defineComponent, onMounted } from 'vue'
  import { setCurrentPageBreadcrumbs } from '@src/core/helpers/breadcrumb'
  import { trans, transChoice } from '@src/core/helpers/translate'
  import { capitalize } from '@src/core/helpers/textUtils'

  export default defineComponent({
    name: 'ProductsIndex',
    setup () {
      onMounted(() => {
        setCurrentPageBreadcrumbs(
          trans('nav.aside.products.sync'),
          [
            capitalize(transChoice('global.product', 2))
          ]
        )
      })
    }
  })
